/**
* DEVELOPER NOTE
*
* If you have to override some stuff? Do it here, but please comment,
* why are you overwriting it and where it is located
*/

/**
* Setting the "mark" style, when a text search matches and marks the result
*/
.mark,
mark {
  background-color: transparent !important;
  font-weight: bold !important;
  padding: 0 !important;
}

/**
* Overwrite the "send to myself" checkbox, for alignment reasons
*/
.quote-customer-contact .mat-checkbox-label {
  line-height: 1 !important;
  white-space: normal;
}

/**
* Device Presets Overwrites
**/
.device-presets {
  spx-card {
    .mat-card {
      height: 100%;
    }
  }

  &--active {
    .mat-card {
      border: solid 2px var(--app-color);
    }
  }
}

/**
* Material Card Overwrites
**/
mat-card {
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
}

mat-card-content {
  overflow: hidden;

  app-table {
    overflow-y: auto;
  }
}

/**
* Material Toggle Button Overwrites
**/
.mat-button-toggle-checked {
  background-color: var(--primary);
  color: var(--primary-contrast) !important;
  spx-icon {
    svg {
      path {
        fill: var(--primary-contrast);
        stroke: transparent;
      }
    }
  }
}

.mat-button-toggle-label-content {
  line-height: 32px !important;
}

/**
* Material Dialog Overwrites
**/
.dialog__content {
  position: relative
}

.device-documents .mat-dialog-content,
.device-documents .dialog__content__container,
.reorder-dialog .mat-dialog-content,
.reorder-dialog .dialog__content__container {
  padding: 0 !important;
}

/**
* Remove the box shadow from the material table
**/
.reorder {
  table {
    box-shadow: none;
  }
}

/**
* Makes a tooltip to break lines on \n
**/
.tooltip-break-line {
  white-space: pre-line;
}

/**
* Font size overwrites
**/
mat-button-toggle {
  font-size: 14px;
}

.mat-option {
  font-size: 14px;
}

/**
* Override button padding for action buttons in a table (that should go into the library)
**/
.mat-column-Actions .spx-button {
  padding: 0 6px !important;
}

/**
* Too long consent text breaking out of mat-card (Registration Page)
* Fix found here: https://github.com/angular/components/issues/8416
**/
mat-checkbox.text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
    
    .mat-checkbox-inner-container {
      margin-top: 3px !important;
    }
  }
}