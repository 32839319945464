@import '../../../node_modules/@seepex/ng-base/scss/core/helpers.scss';

:root {
  @include define-colors('accent-50', #e9ecf5);
  @include define-colors('accent-100', #d9e0f2);
  @include define-colors('accent-200', #cad4f0);
  @include define-colors('accent-300', #abbbe7);
  @include define-colors('accent-400', #8a9fda);
  @include define-colors('accent-500', #6881c6);
  @include define-colors('accent-600', #4862ab);
  @include define-colors('accent-700', #2b4489);
  @include define-colors('accent-800', #142963);
  @include define-colors('accent-900', #05143c);
  @include define-colors('accent-A100', #c5d0f8);
  @include define-colors('accent-A200', #9db1f4);
  @include define-colors('accent-A400', #4778ec);
  @include define-colors('accent-A700', #004ccb);

  @include theme-defaults-vars('primary', 900, 300, 900);
  @include theme-defaults-vars('accent', 700, 500, 900);

  @include app-color('accent-900');
  @include menu-color('accent');
}

.theme-seepex {
  spx-topbar .mat-toolbar {
    background-image: url('../../assets/img/top-bar.jpg') !important;
  }

  .sidenav-container .sidenav__divider {
    background-color: #e62d1e;
  }

  .logo::before {
    background: url('../../assets/img/hidpi_seepex_white_wo_claim.png');
  }
}
