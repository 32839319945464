/**
* Resets margins and paddings for headers, ul, ol and p
*/
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
blockquote {
  padding: 0;
  margin: 0;
}

/**
* Completely resets form items
*/
input,
textarea,
button {
  border: 0;
  border-radius: 0;
  outline: none;
  appearance: none;
  resize: none;
  padding: 0;
}
input:hover, input:active, input:focus,
textarea:hover,
textarea:active,
textarea:focus,
button:hover,
button:active,
button:focus {
  outline: none;
}

/**
* Reset spinner buttons on number input field
*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/**
* Reset button and link behaviour
*/
button,
a {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
}

a:not(.spx-override) {
  cursor: pointer;
  text-decoration: none;
  color: var(--accent-text);
}

a:not(.spx-override):hover {
  color: var(--primary-text);
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

svg {
  display: block;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography p {
  margin: 0;
}

.mark,
mark {
  background-color: transparent !important;
  font-weight: bold !important;
  padding: 0 !important;
}

.material-icons:not(.mat-fab-icon) {
  font-size: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.spx-icon-button .spx-icon.mat-icon {
  line-height: 18px;
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.spx-icon-size-big .spx-icon.mat-icon {
  line-height: 24px;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

/**
* Search bar overwriting
**/
.search .mat-form-field-flex {
  display: flex;
  align-items: center;
}
.search .mat-form-field-prefix, .search .mat-form-field-suffix {
  position: relative;
  top: 6px;
}
.search .mat-form-field-prefix .mat-icon-button,
.search .mat-form-field-prefix .mat-icon, .search .mat-form-field-suffix .mat-icon-button,
.search .mat-form-field-suffix .mat-icon {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 18px !important;
}
.search .mat-form-field-suffix .mat-icon {
  text-align: center;
}
.search .mat-icon-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.26);
}
.search .mat-form-field-wrapper {
  padding-bottom: 10px !important;
}
.search .mat-form-field-underline {
  bottom: 10px !important;
}

.toggle-button-wrapper {
  height: 30px;
}
.toggle-button-wrapper mat-button-toggle-group {
  align-items: center;
  height: 100%;
}
.toggle-button-wrapper .mat-button-toggle-label-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
  line-height: 32px !important;
}
.toggle-button-wrapper .mat-button-toggle-label-content spx-icon {
  margin-right: 8px;
}

/**
* DEVELOPER NOTE
*
* If you have to override some stuff? Do it here, but please comment,
* why are you overwriting it and where it is located
*/
/**
* Setting the "mark" style, when a text search matches and marks the result
*/
.mark,
mark {
  background-color: transparent !important;
  font-weight: bold !important;
  padding: 0 !important;
}

/**
* Overwrite the "send to myself" checkbox, for alignment reasons
*/
.quote-customer-contact .mat-checkbox-label {
  line-height: 1 !important;
  white-space: normal;
}

/**
* Device Presets Overwrites
**/
.device-presets spx-card .mat-card {
  height: 100%;
}
.device-presets--active .mat-card {
  border: solid 2px var(--app-color);
}

/**
* Material Card Overwrites
**/
mat-card {
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
}

mat-card-content {
  overflow: hidden;
}
mat-card-content app-table {
  overflow-y: auto;
}

/**
* Material Toggle Button Overwrites
**/
.mat-button-toggle-checked {
  background-color: var(--primary);
  color: var(--primary-contrast) !important;
}
.mat-button-toggle-checked spx-icon svg path {
  fill: var(--primary-contrast);
  stroke: transparent;
}

.mat-button-toggle-label-content {
  line-height: 32px !important;
}

/**
* Material Dialog Overwrites
**/
.dialog__content {
  position: relative;
}

.device-documents .mat-dialog-content,
.device-documents .dialog__content__container,
.reorder-dialog .mat-dialog-content,
.reorder-dialog .dialog__content__container {
  padding: 0 !important;
}

/**
* Remove the box shadow from the material table
**/
.reorder table {
  box-shadow: none;
}

/**
* Makes a tooltip to break lines on \n
**/
.tooltip-break-line {
  white-space: pre-line;
}

/**
* Font size overwrites
**/
mat-button-toggle {
  font-size: 14px;
}

.mat-option {
  font-size: 14px;
}

/**
* Override button padding for action buttons in a table (that should go into the library)
**/
.mat-column-Actions .spx-button {
  padding: 0 6px !important;
}

/**
* Too long consent text breaking out of mat-card (Registration Page)
* Fix found here: https://github.com/angular/components/issues/8416
**/
mat-checkbox.text-wrap label.mat-checkbox-layout {
  white-space: normal;
}
mat-checkbox.text-wrap label.mat-checkbox-layout .mat-checkbox-inner-container {
  margin-top: 3px !important;
}