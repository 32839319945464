/**
* Resets margins and paddings for headers, ul, ol and p
*/
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
blockquote {
  padding: 0;
  margin: 0;
}

/**
* Completely resets form items
*/
input,
textarea,
button {
  border: 0;
  border-radius: 0;
  outline: none;
  appearance: none;
  resize: none;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

/**
* Reset spinner buttons on number input field
*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/**
* Reset button and link behaviour
*/
button,
a {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
}

a:not(.spx-override) {
  cursor: pointer;
  text-decoration: none;
  color: var(--accent-text);
}

a:not(.spx-override):hover {
  color: var(--primary-text);
}
