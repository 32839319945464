// Default icon size
$iconSize: 18px;

// Navigation icon size
$iconSizeBig: 24px;

.material-icons:not(.mat-fab-icon) {
  font-size: $iconSize;
  width: $iconSize;
  height: $iconSize;
  line-height: $iconSize;
}

.spx-icon-button {
  .spx-icon.mat-icon {
    line-height: $iconSize;
    font-size: $iconSize;
    width: $iconSize;
    height: $iconSize;
  }
}

.spx-icon-size-big {
  .spx-icon.mat-icon {
    line-height: $iconSizeBig;
    font-size: $iconSizeBig;
    width: $iconSizeBig;
    height: $iconSizeBig;
  }
}
