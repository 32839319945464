// hmtl/body and global stuff
// ---------------------------------
html {
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Set Image and Objects to have a max-width of 100%
// ---------------------------------
embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

svg {
  display: block;
}

// Headings
// ---------------------------------
.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography p {
  margin: 0;
}

.mark,
mark {
  background-color: transparent !important;
  font-weight: bold !important;
  padding: 0 !important;
}
