/**
* Search bar overwriting
**/
.search {
  .mat-form-field-flex {
    display: flex;
    align-items: center;
  }

  .mat-form-field-prefix, .mat-form-field-suffix  {
    position: relative;
    top: 6px;

    .mat-icon-button,
    .mat-icon {
      width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
      font-size: 18px !important;
    }
  }

  .mat-form-field-suffix .mat-icon {
    text-align: center;
  }

  .mat-icon-button.mat-button-disabled.mat-button-disabled {
    color: rgba(255, 255, 255, 0.26);
  }

  .mat-form-field-wrapper {
    padding-bottom: 10px !important;
  }

  .mat-form-field-underline {
    bottom: 10px !important;
  }
}

.toggle-button-wrapper {
  height: 30px;

  mat-button-toggle-group {
    align-items: center;
    height: 100%;
  }

  .mat-button-toggle-label-content {
    display: flex !important;
    justify-content: center;
    align-items: center;
    line-height: 32px !important;

    spx-icon {
      margin-right: 8px;
    }
  }
}
